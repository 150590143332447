import { FC, MouseEventHandler, useEffect, useRef, useState } from 'react';
import { ApolloError, useQuery } from '@apollo/client';

import { BackButton, BackLink } from '@/Components/Back';

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Pagination } from 'swiper';

import partnerLogo from '@/Assets/partnerLogo.svg';
import instagram from '@/Assets/instagram.svg';
import whatsapp from '@/Assets/whatsapp.svg';
import LeftArrow from '@/Assets/subcategory-arrow-left.svg';

import { separateNumber } from '@/utils';
import { getProduct } from '@/Services/apollo/gqls';
import { funcs } from '@/Services';
import { FullScreenInfo } from '@/Components/FullScreenInfo';
import { useLocation, useParams } from 'react-router-dom';
import { IModifiedProductId, SubProduct } from '@/Services/interfaces';
import { GoodsItem } from '@/Components/GoodsItem';

import styles from './styles/product.module.scss';

export const Product: FC<{ is360?: boolean }> = ({ is360 = false }) => {
  const { state } = useLocation() as unknown as {
    state: { product: IModifiedProductId; subProducts: SubProduct[] };
  };

  const sliderRef = useRef<any>();
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const [productPhotos, setProductPhotos] = useState([]);
  const [product, setProduct] = useState(state?.product || null);
  const [isFullScreenSlide, setIsFullScreenSlide] = useState(false);
  const [isError, setErrorStatus] = useState<ApolloError | undefined>(
    undefined,
  );

  const { ID: productId, budgetID } = useParams();
  const { error, data, loading } = useQuery(getProduct, {
    variables: {
      productId: productId,
    },
  });

  useEffect(() => {
    if (error) {
      setErrorStatus(error);
      return;
    }

    if (!data) {
      return;
    }

    const product = data.getProduct;

    if (!is360) {
      setProductPhotos(product.photos);
      return;
    }

    setProduct(product);
    setProductPhotos(product.photos);
  }, [is360, data, loading, error]);

  const handleImageClick = (index: number) => {
    setSlideIndex(index);
    setIsFullScreenSlide(true);
  };

  const closeSliderHandler: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsFullScreenSlide(false);
  };

  if (loading) {
    return <FullScreenInfo text="Загрузка" />;
  }

  if (isError) {
    return <div>Ошибка: {isError}</div>;
  }

  if (product === null) {
    return <div>Продукт не загружен</div>;
  }

  return (
    <>
      <header className={styles.header}>
        {is360 && <BackLink href={`/${budgetID}/`} />}
        {!is360 && <BackButton />}
      </header>
      <main className={styles.main}>
        <div className={styles.logoAndTitle}>
          <img
            className={styles.logo}
            src={
              product.shop?.icon?.url
                ? `https://api.logovo.kz/${product.shop?.icon?.url}`
                : partnerLogo
            }
            width={33}
            height={33}
            alt={`логотип компании ${product.shop?.title}`}
          />

          <h1 className={styles.title}>{product.title}</h1>
        </div>
        <div
          className={`${styles.sliderWrapper} ${
            isFullScreenSlide ? styles.fullScreen : ''
          }`}
        >
          {isFullScreenSlide && (
            <div className={styles.fullScreenSwiperInterface}>
              <button
                type="button"
                onClick={closeSliderHandler}
                className={styles.closeBtn}
              >
                <img
                  className={styles.backImg}
                  src={LeftArrow}
                  width={12}
                  height={12}
                  alt="закрыть"
                />
              </button>
              <div className={styles.sliderData}>
                {slideIndex + 1}/{productPhotos.length}
              </div>
            </div>
          )}

          <Swiper
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            ref={sliderRef}
            pagination={{
              clickable: true,
              renderBullet: (_, className: string) => {
                return (
                  '<span class="' +
                  className +
                  ` ${styles.swiperBtn}` +
                  '"></span>'
                );
              },
            }}
            onSlideChange={(slide) => {
              setSlideIndex(slide.realIndex);
            }}
            slidesPerView={1}
            loop={true}
            modules={[Pagination]}
            initialSlide={1}
          >
            {productPhotos.map(({ url }, index) => {
              return (
                <SwiperSlide key={url} onClick={() => handleImageClick(index)}>
                  <div className={styles.imageWrapper}>
                    <img
                      src={`https://api.logovo.kz/${url}`}
                      alt={product.title}
                      loading="lazy"
                      className={`${styles.swiperImage} ${
                        isFullScreenSlide ? styles.swiperImageFullScreen : ''
                      }`}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <section className={styles.priceInfo}>
          {!is360 && (
            <>
              <div className={styles.sumPrice}>
                {separateNumber(product.price_with_discount)} ₸
              </div>
              <div className={styles.itemPriceAndCount}>
                <span>
                  {separateNumber(product.price)}{' '}
                  {`₸${product?.unit?.value ? `/${product?.unit?.value}` : ''}`}
                </span>{' '}
                x{' '}
                <span>
                  {product.area.toFixed(1)} {`${product.unit?.value || ''}`}
                </span>
              </div>
            </>
          )}
          {is360 && (
            <div className={styles.itemPriceAndCount}>
              <span>{separateNumber(product.price)} ₸</span>
            </div>
          )}
        </section>
        <section>
          <div className={styles.info}>
            <p>
              <strong>Описание:</strong> <br />
              {product.discount_description}
            </p>
          </div>
          <div className={styles.info}>
            <p>
              <strong>Магазин: </strong>
              <br /> {product.shop?.title}
            </p>
            <p>
              <strong>Адрес:</strong>
              <br /> {product.shop?.address}
            </p>
          </div>
        </section>
        <nav className={styles.nav}>
          <div className={styles.socialLinks}>
            <a
              className={styles.link}
              href={product.shop?.instagram}
              target="_blank"
              rel="noreferrer"
            >
              {product.shop?.instagram.includes(
                'https://www.instagram.com/',
              ) ? (
                <>
                  <img src={instagram} alt="логотип instagram" />
                  Instagram
                </>
              ) : (
                'Сайт'
              )}
            </a>
            <a
              className={styles.link}
              target="_blank"
              rel="noreferrer"
              href={`https://wa.me/${product.shop?.phone.replace(
                // eslint-disable-next-line no-useless-escape
                /\+|\s|\-|[\(\)]/g,
                '',
              )}?text=Здравствуйте%2C+меня+интересует+${product.title}`}
            >
              <img src={whatsapp} alt="логотип whatsapp" />
              WhatsApp
            </a>
          </div>

          <a
            href={`tel:${product.shop?.phone}`}
            className={styles.link}
            target={funcs.checkiOS() ? "_parent" : ""}
            type="button"
          >
            Телефон
          </a>
        </nav>
      </main>

      {!!state?.subProducts?.length && (
        <footer className={styles.footer}>
          <h2 className={styles.subtitle}>Сопровождающие товары</h2>
          <section className={styles.subproductsWrapper}>
            {state?.subProducts?.map(
              ({ id_product, area, price_with_discount }) => (
                <GoodsItem
                  key={id_product._id}
                  product={{ ...id_product, area, price_with_discount }}
                />
              ),
            )}
          </section>
        </footer>
      )}
    </>
  );
};
