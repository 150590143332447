
import { ApolloClient, InMemoryCache, split, ApolloLink } from "@apollo/client";

import { createUploadLink } from 'apollo-upload-client';

import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';

import config from '@/Config/index';

const apolloClientSetup = () => {

  const token = localStorage.getItem(config.TOKEN);

  const httpLink = createUploadLink({
    uri: config.graphqlURI
  });

  const wsLink = new WebSocketLink({
    uri: config.graphqlWSURI,
    options: {
      reconnect: true,
      connectionParams: {
        authToken: token ? token : 'none'
      },
    }
  });

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  );

  const authLink = new ApolloLink((operation, forward) => {

    operation.setContext({
      headers: {
        authorization: token ? token : 'none'
      }
    });
    return forward(operation);
  });

  const client = new ApolloClient({
    link:authLink.concat(link),
    cache:new InMemoryCache()
  });

  return client;

}

export default apolloClientSetup;

