import React, { FC, ReactNode } from 'react';
import styles from './blueLink.module.scss';

interface IBlueLink {
  children: ReactNode;
  href: string;
  className?: string;
}

export const BlueLink: FC<IBlueLink> = ({ children, className, href }) => {
  return (
    <a
      href={href}
      rel="noreferrer"
      target="_blank"
      className={`${styles.blueLink} ${className}`}
    >
      {children}
    </a>
  );
};
