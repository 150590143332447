import { FC } from 'react';

import LeftArrow from '@/Assets/subcategory-arrow-left.svg';
import styles from './styles/backButton.module.scss';
import { useNavigate } from 'react-router-dom';

export const BackButton: FC = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles.back}>
      <button onClick={handleBack} className={styles.link}>
        <img className={styles.backImg} src={LeftArrow} alt="Вернуться назад" />
      </button>
    </div>
  );
};
