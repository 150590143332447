import React, { FC } from 'react';
import styles from './fullScreenInfo.module.scss';

export const FullScreenInfo: FC<{ text: string }> = ({ text }) => {
  return (
    <div className={styles.infoWrapper}>
      <h3 className={styles.infoText}>{text}</h3>
    </div>
  );
};
