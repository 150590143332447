import { MutableRefObject, useEffect } from 'react';
import { OpenedDetailsState } from '..';

type IUseSaveDetailsState = (
  data: any[] | undefined,
  allOpenedDetailsStatus: MutableRefObject<OpenedDetailsState>,
  detailsRef: MutableRefObject<
    {
      index: number;
      element: HTMLDetailsElement;
    }[]
  >,
  detailsType: keyof OpenedDetailsState,
) => void;

export const useSaveDetailsState: IUseSaveDetailsState = (
  data,
  allOpenedDetailsStatus,
  detailsRef,
  detailsType,
) => {
  useEffect(() => {
    allOpenedDetailsStatus.current.roomDropDownDetailsState = [];
  }, [allOpenedDetailsStatus]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (!allOpenedDetailsStatus.current[detailsType].length) {
      data?.forEach((_, index) => {
        allOpenedDetailsStatus.current[detailsType].push({
          index,
          isOpened: false,
        });
      });
    }

    detailsRef.current.forEach(({ index: refItemIndex, element }) => {
      element.addEventListener('toggle', () => {
        allOpenedDetailsStatus.current[detailsType].forEach(({ index }) => {
          if (index === refItemIndex) {
            allOpenedDetailsStatus.current[detailsType][refItemIndex] = {
              index: refItemIndex,
              isOpened: element.open,
            };
          }
        });
      });
    });
  }, [allOpenedDetailsStatus, data, detailsRef, detailsType]);
};
