import { FC, MutableRefObject } from 'react';
import { GoodsItem } from '@/Components/GoodsItem';

import dropdownDown from '@/Assets/subcategory-arrow-down.svg';

import styles from './styles/roomDropdown.module.scss';
import { Oops } from '../Oops';
import { separateNumber } from '@/utils';
import { Product } from '@/Services/interfaces';
import { OpenedDetailsState } from '../../';

export const RoomDropdown: FC<{
  title: string;
  price: number;
  products: Product[];
  index: number;
  allOpenedDetailsStatus: MutableRefObject<OpenedDetailsState>;
  detailsRef: MutableRefObject<
    { index: number; element: HTMLDetailsElement }[]
  >;
}> = ({
  title = '',
  price = 0,
  products = [],
  index,
  detailsRef,
  allOpenedDetailsStatus,
}) => {
  const isOpenDetails =
    allOpenedDetailsStatus.current.roomDropDownDetailsState[index]?.isOpened;
  return (
    <details
      open={isOpenDetails}
      ref={(element) => (detailsRef.current[index] = { index, element })}
      className={styles.room}
      icon-attribute={dropdownDown}
    >
      <summary className={styles.roomTitle}>
        <span>{title}</span>
        <span>{separateNumber(price)} ₸</span>
      </summary>
      <section>
        {products.length !== 0 ? (
          products.map(
            (
              { id_product, area, price_with_discount, sub_products },
              index,
            ) => {
              return (
                <GoodsItem
                  key={index}
                  product={{ ...id_product, area: +area, price_with_discount }}
                  subProducts={sub_products}
                />
              );
            },
          )
        ) : (
          <Oops />
        )}
      </section>
    </details>
  );
};
