type TypeConfig = {
  public: string;
  logovoView: string;
  app_version: string;
  graphqlURI: string;
  graphqlWSURI: string;
  TOKEN: string;
};

const Config: TypeConfig = {
  public: 'https://api.logovo.kz/',
  logovoView: 'https://viewer.logovo.kz/',
  app_version: '1.0.0',
  graphqlURI: 'https://api.logovo.kz/graphql',
  graphqlWSURI: 'wss://api.logovo.kz/graphql',
  TOKEN: 'SESSION_TOKEN',
};

export default Config;
