import { FC, MutableRefObject, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import dropdownDown from '@/Assets/subcategory-arrow-down.svg';

import styles from './styles/main.module.scss';
import { getNumberFromString, separateNumber } from '@/utils';
import { Modal } from '@/Components/Modal';
import { BlueLink } from '@/Components/BlueLink';
import Config from '@/Config';
import { useQuery } from '@apollo/client';
import { getBudgetOfFlatTemplate } from '@/Services/apollo/gqls';
import { FullScreenInfo } from '@/Components/FullScreenInfo';
import { useParams } from 'react-router-dom';
import { IGetBudgetOfFlatTemplate } from '@/Services/interfaces';
import { OpenedDetailsState } from '../../';
import { useSaveDetailsState } from '@/customHook/useSaveDetailsState';

export const Main: FC<{
  allOpenedDetailsStatus: MutableRefObject<OpenedDetailsState>;
}> = ({ allOpenedDetailsStatus }) => {
  const [isModalOpen, setModalStatus] = useState(false);
  const { search } = useLocation();
  const { budgetID } = useParams();
  const flatID = search.split('=')[1];
  const { error, data, loading } = useQuery<{
    getBudgetOfFlatTemplate: IGetBudgetOfFlatTemplate;
  }>(getBudgetOfFlatTemplate, {
    variables: {
      flatTemplateId: budgetID,
    },
  });

  const detailsRef = useRef<{ index: number; element: HTMLDetailsElement }[]>(
    [],
  );

  useSaveDetailsState(
    data?.getBudgetOfFlatTemplate?.categories,
    allOpenedDetailsStatus,
    detailsRef,
    'mainPageDetailsState',
  );

  if (loading) {
    return <FullScreenInfo text="Загрузка" />;
  }

  if (error) {
    return (
      <FullScreenInfo text="Извините, для данной квартиры бюджет не найден" />
    );
  }

  const { getBudgetOfFlatTemplate: budgetTemplate } = data;
  const {
    FlatTemplate: flatInfo,
    template: { total_price: totalBudgetPrice },
    categories,
  } = budgetTemplate;

  const handleCloseModal = () => setModalStatus(false);

  const squareMetres = getNumberFromString(flatInfo.title.split('/')[1]);

  return (
    <>
      <header className={styles.header}>
        <h2 className={styles.title}>Бюджет</h2>
      </header>
      <section className={styles.container}>
        <section className={styles.apartmentInfo}>
          <button
            type="button"
            onClick={() => setModalStatus(true)}
            className={styles.imgWrapper}
          >
            <img
              src={`https://api.logovo.kz/${flatInfo.plan_photo.url}`}
              alt="планировка квартиры"
            />
          </button>
          <div className={styles.infoWrapper}>
            <section className={styles.info}>
              <div className={styles.infoCell}>{flatInfo.count_rooms}к</div>
              <div className={styles.infoCell}>{squareMetres} м²</div>
            </section>
            {totalBudgetPrice && (
              <section className={styles.budgetTotal}>
                <strong>{separateNumber(totalBudgetPrice)} ₸</strong>
                Бюджет интерьера
              </section>
            )}
            {flatID && (
              <BlueLink href={`${Config.logovoView}${flatID}`}>3D Тур</BlueLink>
            )}
          </div>
        </section>
        <section>
          {!!categories?.length &&
            categories.map(
              ({ title, price, child_categories, status_view }, index) => {
                if (!status_view) {
                  return null;
                }

                const isOpenDetails =
                  allOpenedDetailsStatus.current.mainPageDetailsState[index]
                    ?.isOpened;

                return (
                  <details
                    open={isOpenDetails}
                    className={styles.category}
                    key={title}
                    ref={(element) =>
                      (detailsRef.current[index] = { index, element })
                    }
                  >
                    <summary className={styles.categoryTitle}>
                      <div>
                        <span>{title}</span>
                        <span>{separateNumber(price)} ₸</span>
                      </div>
                    </summary>
                    {child_categories.map(
                      ({ _id, title, price, status_view }) => {
                        if (!status_view) {
                          return null;
                        }

                        return (
                          <Link
                            to={_id}
                            key={_id}
                            className={styles.subcategoryRoute}
                            icon-attribute={dropdownDown}
                          >
                            <span className={styles.subcategoryTitle}>
                              {title}
                            </span>
                            <span>{separateNumber(price)} ₸</span>
                          </Link>
                        );
                      },
                    )}
                  </details>
                );
              },
            )}
        </section>
      </section>

      <Modal isOpen={isModalOpen} handleClose={handleCloseModal}>
        <div className={styles.imgContainer}>
          <img
            className={styles.modalPhoto}
            src={`https://api.logovo.kz/${flatInfo.plan_photo.url}`}
            alt="планировка квартиры"
          />
        </div>
      </Modal>
    </>
  );
};
