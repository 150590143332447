import { gql } from "@apollo/client";

export const getBudgetOfFlatTemplate = gql`
	query ($flatTemplateId: String) {
		getBudgetOfFlatTemplate(flat_template_id: $flatTemplateId) {
			FlatTemplate {
				square_metres
				count_rooms
				title
				preview_photo {
					title
					url
				}
				plan_photo {
					title
					url
				}
			}
			template {
				title
				total_price
			}
			categories {
				title
				price
				status_view
				child_categories {
					_id
					title
					price
					status_view
					child_categories {
						title
						price
						marker
						status_view
						products {
							area
							price_with_discount
							id_product {
								_id
								title
								link
								discount
								price
								unit {
									value
								}
								shop {
									instagram
									phone
									address
									icon {
										url
										type
									}
									title
								}
								photo {
									url
									type
								}
								discount_description
							}
							sub_products {
								area
								price_with_discount
								id_product {
									_id
									price
                  title
									unit {
										value
									}
									photo {
										url
									}
								}
							}
						}
					}
					products {
						area
						price_with_discount
						id_product {
							_id
							title
							link
							discount
							price
							unit {
								value
							}
							shop {
								instagram
								phone
								address
								icon {
									url
									type
								}
								title
							}
							photo {
								url
								type
							}
							discount_description
						}
						sub_products {
							area
							price_with_discount
							id_product {
								_id
								title
								link
								discount
								price
								unit {
									value
								}
								shop {
									instagram
									phone
									address
									icon {
										url
										type
									}
									title
								}
								photo {
									url
									type
								}
								discount_description
							}
						}
					}
				}
			}
		}
	}
`;
