import { useState, FC } from 'react';
import { Link, useParams } from 'react-router-dom';

import styles from './styles/goodsItem.module.scss';

import { separateNumber } from '@/utils';
import { IModifiedProductId, SubProduct,  } from '@/Services/interfaces';
import { Modal } from '../Modal';

import LeftArrow from '@/Assets/subcategory-arrow-left.svg';

export const GoodsItem: FC<{
  product: IModifiedProductId;
  subProducts?: SubProduct[];
}> = ({ product, subProducts }) => {
  const {
    _id,
    title,
    price,
    discount,
    photo,
    area,
    price_with_discount,
    unit,
  } = product;

  const params = useParams();

  const getSumOfSubProducts = () => {
    const totalPrice = 0;
    if (subProducts?.length) {
      return subProducts.reduce(
        (accumulator: number, { price_with_discount }) =>
          accumulator + price_with_discount,
        totalPrice,
      );
    } else {
      return null;
    }
  };

  const [isModalOpen, setModalStatus] = useState(false);

  const handleOpenModal = () => setModalStatus(true);
  const handleCloseModal = () => setModalStatus(false);

  return (
    <article className={styles.item}>
      <Link
        className={styles.itemLink}
        to={
          Object.keys(params)?.length === 3
            ? `/${params.budgetID}/${params.subcategoryID}/${_id}`
            : _id
        }
        state={{ product, subProducts }}
      >
        <section className={styles.itemInfo}>
          <div
            className={styles.itemImgContainer}
            discount-attribute={`${discount || 0}%`}
          >
            <img src={`https://api.logovo.kz/${photo.url}`} alt={title} />
          </div>
          <div>
            <h2 className={styles.itemTitle}>{title}</h2>
            <div>
              <div className={styles.itemPriceAndCount}>
                <span>
                  {separateNumber(price)}{' '}
                  {`₸${unit?.value ? `/${unit?.value}` : ''}`}
                </span>{' '}
                x{' '}
                <span>
                  {(+area).toFixed(1)} {`${unit?.value || ''}`}
                </span>
              </div>
              <div className={styles.itemSum}>
                {separateNumber(price_with_discount)} ₸
              </div>
            </div>
          </div>
        </section>
      </Link>
      {!!subProducts?.length && (
        <button
          type="button"
          onClick={() => handleOpenModal()}
          className={styles.openButton}
        >
          <div>
            Сопровождающие товары:{' '}
            <strong>{separateNumber(getSumOfSubProducts())} ₸</strong>
          </div>

          <img
            src={LeftArrow}
            alt="перейти к сопровождающим товарам"
            height={19}
            width={19}
          />
        </button>
      )}
      <Modal
        isOpen={isModalOpen}
        headerTitle="Сопровождающие товары"
        handleClose={handleCloseModal}
      >
        {!!subProducts?.length &&
          subProducts.map(({ id_product, area, price_with_discount }) => (
            <GoodsItem
              key={id_product._id}
              product={{ ...id_product, area, price_with_discount }}
            />
          ))}
      </Modal>
    </article>
  );
};
