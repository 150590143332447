import { gql } from "@apollo/client";

export const getProduct = gql`
  query($productId: String) {
    getProduct(product_id: $productId) {
      _id
      title
      link
      discount
      price
      shop {
        instagram
        phone
        address
        icon {
          url
          type
        }
        title
      }
      photo {
        url
        type
      }
      photos {
        url
      }
      discount_description
    }
  }
`;
