import { FC, MutableRefObject, useRef } from 'react';
import { RoomDropdown } from '@/Components/RoomDropdown';
import { BackButton } from '@/Components/Back';
import { GoodsItem } from '@/Components/GoodsItem';

import styles from './styles/subcategory.module.scss';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { getBudgetOfFlatTemplate } from '@/Services/apollo/gqls';
import { FullScreenInfo } from '@/Components/FullScreenInfo';
import {
  ChildCategory2,
  IGetBudgetOfFlatTemplate,
} from '@/Services/interfaces';
import { OpenedDetailsState } from '../../';
import { useSaveDetailsState } from '@/customHook/useSaveDetailsState';

export const Subcategory: FC<{
  allOpenedDetailsStatus: MutableRefObject<OpenedDetailsState>;
}> = ({ allOpenedDetailsStatus }) => {
  const { budgetID, subcategoryID } = useParams();
  const { error, data, loading } = useQuery<{
    getBudgetOfFlatTemplate: IGetBudgetOfFlatTemplate;
  }>(getBudgetOfFlatTemplate, {
    variables: {
      flatTemplateId: budgetID,
    },
  });

  const detailsRef = useRef<{ index: number; element: HTMLDetailsElement }[]>(
    [],
  );

  const getSubcategory = () => {
    let subcategoryData: ChildCategory2;
    data?.getBudgetOfFlatTemplate.categories?.forEach((subcategories) =>
      subcategories.child_categories.forEach((subcategory) => {
        if (subcategory._id === subcategoryID) {
          return (subcategoryData = subcategory);
        }
      }),
    );

    return subcategoryData;
  };

  const subcategoryData = getSubcategory();

  useSaveDetailsState(
    subcategoryData?.child_categories,
    allOpenedDetailsStatus,
    detailsRef,
    'roomDropDownDetailsState',
  );

  if (loading) {
    return <FullScreenInfo text="Загрузка" />;
  }

  if (error) {
    return <FullScreenInfo text={`Извините, произошла ошибка: ${error[0]}`} />;
  }

  return (
    <>
      <header className={styles.header}>
        <BackButton />
        <h1 className={styles.pageTitle}>{subcategoryData?.title}</h1>
      </header>
      <section className={styles.subcategoryItems}>
        {subcategoryData?.child_categories.map(
          ({ marker, title, price, products, status_view }, index) => {
            if (marker || !status_view) {
              return null;
            }

            return (
              <RoomDropdown
                detailsRef={detailsRef}
                allOpenedDetailsStatus={allOpenedDetailsStatus}
                key={title}
                title={title}
                price={price}
                products={products}
                index={index}
              />
            );
          },
        )}
      </section>

      <section className={styles.subcategoryItems}>
        {subcategoryData?.products.map(
          ({ id_product, area, price_with_discount, sub_products }, index) => (
            <GoodsItem
              key={index}
              product={{ ...id_product, area, price_with_discount }}
              subProducts={sub_products}
            />
          ),
        )}
      </section>
    </>
  );
};
