import { gql } from '@apollo/client';

export const getUser = gql`
  query {
    getUser {
      _id
      email
      role {
        type
      }
      createdAt
      builder {
        _id
        title
      }
    }
  }
`;

export const authentication = gql`
  mutation ($profile: UserInput) {
    authentication(profile: $profile) {
      _id
      email
      token
    }
  }
`;

export const changePassword = gql`
  mutation ($fields: ChangePasswordInput) {
    changePassword(fields: $fields)
  }
`;
