import React, { useRef } from 'react';import ReactDOM from 'react-dom';

import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Main } from '@/Containers/Main';
import { Subcategory } from '@/Containers/Subcategory';
import { Product } from '@/Containers/Product';

import { apollo } from '@/Services';

import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import '@/Services/default/styles/index.module.scss';

export interface DetailState {
  index: number;
  isOpened: boolean;
}

export interface OpenedDetailsState {
  mainPageDetailsState: DetailState[];
  roomDropDownDetailsState: DetailState[];
}

const App = () => {
  const openedDetailsStatus = useRef<OpenedDetailsState>({
    mainPageDetailsState: [],
    roomDropDownDetailsState: [],
  });

  return (
    <Routes>
      <Route path="/" element={'Бюджет не выбран'} />
      <Route
        path=":budgetID"
        element={<Main allOpenedDetailsStatus={openedDetailsStatus} />}
      />
      <Route
        path=":budgetID/:subcategoryID"
        element={<Subcategory allOpenedDetailsStatus={openedDetailsStatus} />}
      />
      <Route path=":budgetID/:subcategoryID/:ID" element={<Product />} />
      <Route path="360/:budgetID/:ID" element={<Product is360 />} />
    </Routes>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={apollo()}>
      <Router>
        <App />
      </Router>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
