import { FC, KeyboardEvent, ReactNode, useEffect, useRef } from 'react';import { CSSTransition } from 'react-transition-group';
import { PortalWrapper } from '../PortalWrapper';
import styles from './styles/modal.module.scss';

import LeftArrow from '@/Assets/subcategory-arrow-left.svg';

export const Modal: FC<{
  children: ReactNode;
  headerTitle?: string;
  isOpen: boolean;
  handleClose: () => void;
}> = ({ children, headerTitle = '', isOpen, handleClose }) => {
  const nodeRef = useRef(null);
  useEffect(() => {
    const closeOnEscapeKey = (event: unknown) => {
      if ((event as KeyboardEvent).key === 'Escape') {
        handleClose();
      }
    };

    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [handleClose]);

  useEffect(() => {
    if (isOpen) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    }

    return () => {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    };
  }, [isOpen]);

  return (
    <PortalWrapper wrapperId="react-portal-modal-container">
      <CSSTransition
        in={isOpen}
        timeout={{ appear: 50, enter: 200, exit: 250 }}
        unmountOnExit
        classNames={{
          enterActive: styles.modalEnterDone,
          enterDone: styles.modalEnterDone,
          exitActive: styles.modalExit,
          exitDone: styles.modalExit,
        }}
        nodeRef={nodeRef}
      >
        <section className={styles.modal} ref={nodeRef}>
          <header className={styles.modalHeader}>
            <button
              type="button"
              onClick={handleClose}
              className={styles.modalClose}
            >
              <img
                className={styles.backImg}
                src={LeftArrow}
                alt="Закрыть модальное окно"
              />
            </button>
            <h2 className={styles.modalHeaderTitle}>{headerTitle}</h2>
          </header>

          <div className={styles.modalContent}>{children}</div>
        </section>
      </CSSTransition>
    </PortalWrapper>
  );
};
